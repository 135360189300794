<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-card-title>
          <v-icon
            @click="pushRouteToView('users')"
            class="mr-4"
            style="cursor: pointer"
          >
            mdi-arrow-left
          </v-icon>
          <v-icon class="mr-2">mdi-account-group</v-icon>
          {{ user.id ? 'Editar Usuário' : 'Adicionar Usuário' }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="formUser"
            v-model="formIsValid"
            v-on:submit.prevent="userSubmit(user.id)"
          >
            <v-col md="12">
              <v-row>
                <v-col xl="2" lg="2" md="2" sm="6" v-show="user.username">
                  <v-text-field
                    v-model="user.username"
                    label="Usuário"
                    placeholder="Usuário"
                    disabled
                    outlined
                    v-uppercase
                  ></v-text-field>
                </v-col>
                <v-col xl="3" lg="3" md="3" sm="6">
                  <v-text-field
                    v-model="user.name"
                    label="Nome"
                    placeholder="Nome completo do usuário"
                    :rules="textRules"
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col xl="4" lg="5" md="5" sm="6">
                  <v-text-field
                    label="E-mail"
                    v-model="user.email"
                    placeholder="E-mail do usuário"
                    :rules="emailRules"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col xl="2" lg="3" md="3" sm="6">
                  <v-text-field
                    v-model="user.phone"
                    label="Telefone"
                    placeholder="Informe um telefone."
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col xl="3" lg="3" md="3" sm="6">
                  <v-text-field
                    type="password"
                    label="Senha"
                    v-model="user.password"
                    placeholder="Senha do usuário"
                    :rules="[passwordRule]"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col sm="12">
                  <h3>Permissões</h3>
                  <v-divider></v-divider>
                </v-col>
                <v-col
                  xl="4"
                  lg="4"
                  md="4"
                  sm="6"
                  v-for="(permission, i) in permissions"
                  :key="i"
                >
                  <v-select
                    :items="levels"
                    :return-object="true"
                    item-text="desc"
                    item-value="id"
                    :value="permissionValue(permission.id)"
                    :label="permission.desc"
                    placeholder="Escolha o nível de acesso..."
                    :rules="textRules"
                    @input="(level) => updatePermissions(permission, level)"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="text-right">
                  <v-btn
                    color="error"
                    v-if="user.id"
                    class="mr-4"
                    @click="reset"
                  >
                    <v-icon>mdi-close</v-icon>
                    Cancelar
                  </v-btn>
                  <v-btn color="error" v-else class="mr-4" @click="reset">
                    <v-icon>mdi-close</v-icon>
                    Limpar
                  </v-btn>
                  <v-btn type="submit" color="success">
                    <v-icon left v-if="user.id">mdi-content-save</v-icon>
                    <v-icon left v-else>mdi-content-save</v-icon>
                    Salvar
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { mask } from "vue-the-mask";
import { CHANGE_PROGRESS } from "@/store/mutations/mutations.type";
import {
  PERMISSIONS_GET,
  USER_GET,
  USER_EDIT,
  USER_CREATE,
} from "@/store/actions/user.type";
import {
  USER_RESET,
  USER_RESET_STATE,
  UPDATE_PERMISSION,
} from "@/store/mutations/user.type";

export default {
  name: "CadastroUsuarios",
  directives: { mask },
  props: {
    id: {
      default: 0,
      type: Number,
      required: false,
    },
  },

  components: {},
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.dispatch(USER_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    store.dispatch(USER_RESET_STATE);
    if (to.params.id !== undefined)
      await store.dispatch(USER_GET, to.params.id);
    await store.dispatch(PERMISSIONS_GET);

    return next();
  },
  data: () => ({
    selectMaterialOK: "",
    formIsValid: false,
    textRules: [
      (v) => !!v || "Este campo é obrigatório",
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],
    cpfRule: [
      (v) => !!v || "CPF é obrigatório",
      (strCPF) => {
        if (!strCPF) return false;
        strCPF = strCPF.replace(/\D/g, "");
        let Soma;
        let Resto;
        Soma = 0;
        if (strCPF == "00000000000") return "CPF Inválido";
        let i = 0;
        for (i = 1; i <= 9; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return "CPF Inválido";

        Soma = 0;
        for (i = 1; i <= 10; i++)
          Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if (Resto == 10 || Resto == 11) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return "CPF Inválido";
        return true;
      },
    ],
    emailRules: [
      (v) => !!v || "E-mail é obrigatório",
      (value) => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Informe um email válido";
          }
        }
        return false;
      },
    ],
    telRules: [
      (v) => !!v || "Telefone é obrigatório",
      (value) => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern = /(\(?\d{2}\)?\s)?(\d{4,5}-\d{4})$/;
            return pattern.test(value) || "Informe um telefone válido";
          }
        }
        return false;
      },
    ],
  }),
  methods: {
    pushRouteToView(route) {
      this.$router.push({ name: route });
    },
    permissionValue(permission) {
      let permLevel = this.user.permissions.find((p) => p.id === permission);
      if (typeof permLevel !== "undefined") {
        return { id: permLevel.levelID };
      }
      return null;
    },
    updatePermissions(permission, level) {
      this.permissions.find((p, i) => {
        if (p.id === permission.id) {
          let permission = { id: p.id, levelID: level.id };
          this.$store.commit(UPDATE_PERMISSION, {
            i: i,
            permission: permission,
          });
          // this.user.permissions[i] = {id: p.id, levelID: level.id};
        }
      });
    },
    passwordRule(pass) {
      if (!pass && this.id != 0) return true;
      return !!pass || "Este campo é obrigatório";
    },
    reset() {
      if (this.$route.params.id > 0) {
        this.$router.go(-1);
      } else {
        this.$refs.formUser.resetValidation();

        store.commit(USER_RESET);
      }
    },
    userSubmit(id) {
      if (this.$refs.formUser.validate()) {
        let action = id ? USER_EDIT : USER_CREATE;
        this.$store.commit(CHANGE_PROGRESS, true);
        // this.bloqueiaEnvio = true;
        this.$store
          .dispatch(action)
          .then((response) => {
            this.$store.commit(CHANGE_PROGRESS, false);
            this.bloqueiaEnvio = false;
            this.$refs.formUser.resetValidation();
            store.commit(USER_RESET);
            if (id) {
              this.snackbar.snackbar = true;
              this.snackbar.color = "success";
              this.snackbar.text = "Editado com sucesso";
            } else {
              this.snackbar.snackbar = true;
              this.snackbar.color = "success";
              this.snackbar.text = "Adicionado com sucesso";
              
            }

            if (response.data.id) {
              this.$router.push({
                name: "user",
                params: {id: response.data.id}
              });
            } else {
              this.$router.push({
                name: "user",
                params: {id: id}
              });
            }
            
          })
          .catch(({ response }) => {
            this.$store.commit(CHANGE_PROGRESS, false);
            this.snackbar.bloqueiaEnvio = false;
            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao salvar usuário.";
            if (typeof response.data.message !== undefined) {
              this.snackbar.text = response.data.message;
            }
          });
      } else {
        this.snackbar.snackbar = true;
        this.snackbar.color = "error";
        this.snackbar.text = "Preencha o formulário corretamente";
      }
    },
  },
  computed: {
    ...mapGetters(["user", "permissions", "levels", "snackbar"]),
  },
};
</script>
